<template lang="pug">
Container
	h1 {{ header }}

Container(v-if='collectionItem')
	UiProse(class='Text', :html='collectionItem.content', awesome)
</template>

<script lang="ts" setup>
defineComponent({
	name: 'TextPage',
});

const { header, collectionItem } = useMeta();
</script>
